import { inject } from 'aurelia-framework';

@inject('appConfiguration')
class LandingContainer {
  constructor(appConfiguration) {
    this.links = appConfiguration.get('social');
  }
}

export { LandingContainer };
