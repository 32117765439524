class AppUtils {
  sortObjectsList(property, order = 'asc', normalizeStrings = false) {
    const orderN = order === 'asc' ? 1 : -1;
    return (a, b) => {
      let propA = a[property];
      let propB = b[property];
      if (normalizeStrings) {
        propA = propA.toLowerCase();
        propB = propB.toLowerCase();
      }
      let result = 0;
      if (propA > propB) {
        result = 1 * orderN;
      } else if (propB > propA) {
        result = -1 * orderN;
      }

      return result;
    };
  }

  ensureArray(obj) {
    return Array.isArray(obj) ? obj : [obj];
  }

  ensureElementClass(
    element,
    className,
    addIfNotPresent = true,
    removeIfPresent = true
  ) {
    const classNames = Array.isArray(className) ? className : [className];
    let elements;
    if (Array.isArray(element)) {
      elements = element;
    } else if (typeof element === 'string') {
      elements = Array.from(document.querySelectorAll(element));
    } else {
      elements = [element];
    }
    elements.forEach((targetElement) => {
      const classes = Array.from(targetElement.classList);
      classNames.forEach((name) => {
        const hasIt = classes.includes(name);
        if (hasIt && removeIfPresent) {
          targetElement.classList.remove(name);
        } else if (!hasIt && addIfNotPresent) {
          targetElement.classList.add(name);
        }
      });
    });

    return elements;
  }
}

export { AppUtils };
