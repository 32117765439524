import { inject } from 'aurelia-framework';

@inject(Element, 'appUtils')
class DynamicClassCustomAttribute {
  constructor(element, appUtils) {
    this._element = element;
    this._appUtils = appUtils;
  }

  valueChanged(newValue) {
    this._appUtils.ensureElementClass(
      this._element,
      newValue.split(';').map((className) => className.trim()),
      true,
      false
    );
  }
}

export { DynamicClassCustomAttribute };
