import { APIClient } from 'lib/haut/abstracts';

class AppAPI extends APIClient {
  constructor(httpClient, appConfiguration) {
    'inject';

    super(httpClient, appConfiguration.get('api'));
  }

  getMyInformation() {
    return this.get(this.endpoint('members.me'));
  }

  getAuthenticationEndpoint() {
    return this.endpoint('authentication');
  }

  inviteUserToSlack(email) {
    return this.post(this.endpoint('slack.invite'), { email });
  }
}

export { AppAPI };
