import { LogManager } from 'aurelia-framework';
import { ConsoleAppender } from 'aurelia-logging-console';

class AppLogger {
  constructor(appConfiguration) {
    'inject';

    this.mainLoggerName = appConfiguration.get('appName') || 'Application';
    if (appConfiguration.get('debug.loggingEnabled')) {
      LogManager.setLevel(appConfiguration.get('debug.loggingLevel') || 'none');
      LogManager.addAppender(new ConsoleAppender());
    }
  }

  getLogger(name) {
    return LogManager.getLogger(name);
  }

  debug(...args) {
    this._getMainLogger().debug(...args);
  }

  info(...args) {
    this._getMainLogger().info(...args);
  }

  warn(...args) {
    this._getMainLogger().warn(...args);
  }

  error(...args) {
    this._getMainLogger().error(...args);
  }

  _getMainLogger() {
    return this.getLogger(this.mainLoggerName);
  }
}

export { AppLogger };
