class AppObjectStorage {
  _storage = {};

  save(scope, name, fn) {
    const slot = this._getSlot(scope);
    if (!slot[name]) {
      slot[name] = fn();
    }

    return slot[name];
  }

  remove(scope, name) {
    const slot = this._getSlot(scope);
    delete slot[name];
  }

  clean(scope) {
    delete this._storage[scope];
  }

  cleanAll(exclude = []) {
    Object.keys(this._storage)
    .filter((scope) => !exclude.includes(scope))
    .forEach((scope) => {
      delete this._storage[scope];
    });
  }

  _getSlot(scope) {
    if (!this._storage[scope]) {
      this._storage[scope] = {};
    }

    return this._storage[scope];
  }
}

export { AppObjectStorage };
