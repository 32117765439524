import APIClientBase from 'wootils/shared/apiClient';
import 'yetch/polyfill';

export class APIException extends Error {
  constructor(message, status, aborted, context = {}) {
    super(message);

    this._context = Object.assign(
      {
        status,
        aborted,
      },
      context
    );
  }

  get context() {
    return Object.assign({}, this._context);
  }

  get status() {
    return this.context.status;
  }

  get aborted() {
    return this.context.aborted;
  }
}

export class APIClient extends APIClientBase {
  constructor(httpClient, apiConfiguration) {
    if (new.target === APIClient) {
      throw new TypeError('APIClient is an abstract class, it can\'t be instantiated directly');
    }

    super(
      apiConfiguration.url,
      apiConfiguration.endpoints,
      httpClient.fetch.bind(httpClient)
    );


    this._httpClient = httpClient;
    this._apiConfiguration = apiConfiguration;
  }

  fetch(options) {
    const abortController = new AbortController();
    const newOptions = Object.assign({}, options, {
      signal: abortController.signal,
    });

    const request = super.fetch(newOptions);

    const requestProto = Object.getPrototypeOf(request);
    Object.defineProperty(requestProto, 'abort', {
      value: abortController.abort.bind(abortController),
      writable: true,
    });

    return request
    .then((response) => response.data || response)
    .catch((error) => {
      let nextError;
      if (error.name === 'AbortError') {
        nextError = new APIException('Request aborted', 0, true);
      } else {
        nextError = error;
      }

      return Promise.reject(nextError);
    });
  }

  error(response, status) {
    return new APIException(
      this._getMessageFromResponse(response),
      status,
      false
    );
  }

  _getMessageFromResponse(response) {
    let message;
    if (response.error) {
      message = response.error;
    } else if (response.data && response.data && response.data.message) {
      ({ message } = response.data);
    } else if (response.data && response.data && response.data.error) {
      message = response.data.error;
    } else {
      message = 'Unexpected error';
    }

    return message;
  }
}
