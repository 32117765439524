import { MessagesChannel } from '../abstracts';

class AppMessages extends MessagesChannel {
  constructor(appConfiguration, windowUtils) {
    'inject';

    super(
      windowUtils,
      appConfiguration.get('messages.prefix')
    );
  }
}

export { AppMessages };
