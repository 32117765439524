import ObjectUtils from 'wootils/shared/objectUtils';

class AppConfiguration {
  _config = {};

  getConfig() {
    return this._config;
  }

  setConfig(...args) {
    let newConfig;
    if (args.length === 1) {
      [newConfig] = args;
    } else if (args.length > 1) {
      newConfig = ObjectUtils.merge(...args);
    } else {
      newConfig = {};
    }

    this._config = newConfig;
    return this.getConfig();
  }

  get(property) {
    let result;
    if (Array.isArray(property)) {
      result = {};
      property.forEach((name) => {
        result[name] = this.get(name);
      });
    } else {
      result = ObjectUtils.get(this.getConfig(), property);
    }

    return result;
  }
}

export { AppConfiguration };
