import { bindable } from 'aurelia-framework';
import meetupLogo from './assets/meetup.svg';
import slackLogo from './assets/slack.svg';
import refactorDevsLogo from './assets/refactorDevs.svg';

class Intro {
  @bindable meetupLink = '';
  @bindable discordLink = '';
  meetupLogo = meetupLogo;
  slackLogo = slackLogo;
  refactorDevsLogo = refactorDevsLogo;
}

export { Intro };
