import { Redirect } from 'aurelia-router';


class AppRoutingStep {
  constructor(router, appRouting) {
    'inject';

    this._router = router;
    this._appRouting = appRouting;
  }

  run(navigationInstruction, next) {
    let failedValidation = null;
    navigationInstruction.getAllInstructions()
    .some((route) => {
      const {
        fragment,
        config: {
          name,
          settings,
        },
      } = route;
      const validation = this._appRouting.validate(name, fragment, settings);
      if (validation.denied) {
        failedValidation = validation;
      }

      return validation.denied;
    });

    if (failedValidation) {
      const redirection = new Redirect(this._router.generate(
        failedValidation.redirectTo,
        failedValidation.query
      ));

      return next.cancel(redirection);
    }

    return next();
  }
}

export { AppRoutingStep };
