/**
 * This is a very simple value converter that we can use on repeat loops to filter elements
 * using a custom function.
 */
class ListFilterValueConverter {
  /**
   * This is the method called by the framework when the filter is applied. It just takes the list
   * and calls `.filter` with the custom function.
   * @param {Array}                    list The list to filter.
   * @param {function(item:*):boolean} fn   The custom function to filter the list.
   * @return {Array}
   */
  toView(list, fn) {
    return list.filter(fn);
  }
}

export { ListFilterValueConverter };
