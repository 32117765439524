import { boundMethod } from 'autobind-decorator';
import * as TheLogManager from 'aurelia-logging';

class AppErrorPublisher {
  constructor(
    appLogger,
    appUtils,
    eventAggregator,
    windowUtils,
    ERROR_EVENTS
  ) {
    'inject';

    this._appLogger = appLogger;
    this._appUtils = appUtils;
    this._eventAggregator = eventAggregator;
    this._windowUtils = windowUtils;
    this._ERROR_EVENTS = ERROR_EVENTS;
    this._listening = false;
    this._subscription = null;
    this._ignored = [
      /Router navigation failed, and no previous location/i,
    ];

    // Add a fake log appender to track the framework errors.
    TheLogManager.addAppender({
      error: this._onErrorLog,
      debug: () => {},
      info: () => {},
      warn: () => {},
    });
  }

  ignoreError(expression) {
    this._ignored.push(...this._appUtils.ensureArray(expression));
  }

  start() {
    if (!this._listening) {
      this._listening = true;
      this._subscription = this._windowUtils.addEventListener(
        ['error', 'unhandledrejection'],
        this._onError
      );
    }
  }

  stop() {
    if (this._listening) {
      this._listening = false;
      this._subscription.dispose();
    }
  }

  publish(error, log = true) {
    let newError;
    const isError = error instanceof Error;
    if (typeof error === 'string') {
      newError = new Error(error);
    } else if (!isError && error.message) {
      newError = new Error(error.message);
    } else if (!isError) {
      newError = new Error(`${error}`);
    } else {
      newError = error;
    }

    if (log) {
      this._appLogger.error(newError);
    }

    if (!this._ignored.some((expression) => newError.message.match(expression))) {
      this._eventAggregator.publish(this._ERROR_EVENTS.error, error);
    }
  }

  notify(error, customNotification = '', log = true, notificationMetadata = {}) {
    const notification = customNotification || error.message || 'Unexpected error';
    this._eventAggregator.publish(
      this._ERROR_EVENTS.notification,
      notification,
      notificationMetadata
    );

    this.publish(error, log);
  }

  @boundMethod
  _onError(event) {
    event.preventDefault();
    this.publish(event.error || event.reason);
  }

  @boundMethod
  _onErrorLog(logger, error) {
    if (logger.id !== this._appLogger.mainLoggerName) {
      this.publish(error, false);
    }
  }
}

export { AppErrorPublisher };
