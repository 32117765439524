import { bindable, computedFrom } from 'aurelia-framework';
import emailValidator from 'email-validator';

class SlackInviteForm {
  @bindable loading = false;
  @bindable member = null;
  @bindable email = '';
  @bindable error = '';
  @bindable success = false;
  @bindable onCancel = () => {};
  @bindable onConnect = () => {};
  @bindable onGoBack = () => {};
  @bindable onSend = () => {};

  saveForm($event) {
    $event.preventDefault();
    this.onSend({
      email: this.email,
    });
  }

  @computedFrom('member', 'success')
  get showSteps() {
    const hasMember = this.member !== null;
    return {
      first: !this.success && !hasMember,
      second: !this.success && hasMember,
      third: this.success,
    };
  }

  @computedFrom('loading')
  get loadingText() {
    let result;
    if (this.loading) {
      result = this.member ? 'Enviando invitación...' : 'Conectando cuenta...';
    } else {
      result = '';
    }

    return result;
  }

  @computedFrom('email')
  get emailError() {
    return this.email && !emailValidator.validate(this.email);
  }

  @computedFrom('email', 'emailError')
  get disableSaveButton() {
    return !this.email || this.emailError;
  }
}

export { SlackInviteForm };
