import { PLATFORM } from 'aurelia-pal';
import ObjectUtils from 'wootils/shared/objectUtils';
import SimpleStorage from 'wootils/browser/simpleStorage';

class AppLocalStore extends SimpleStorage {
  constructor(appLogger) {
    'inject';

    super({
      window: PLATFORM.global,
      logger: appLogger,
      initialize: false,
    });

    this._options = this._mergeOptions(this._options, {
      storage: this._getStorageConfiguration(),
    });

    this._initialize();
  }

  setWithKey(key, value) {
    return this._setData({
      [key]: value,
    });
  }

  getFromKey(key) {
    return this._getData()[key];
  }

  deleteKey(key) {
    delete this._data[key];
    this._save();
  }

  _getStorageConfiguration() {
    return {
      name: 'appLocalStore',
      key: 'app-local-store',
    };
  }

  _setResolvedData(data, save) {
    return super._setResolvedData(
      ObjectUtils.merge(this._getData(), data),
      save
    );
  }
}

export { AppLocalStore };
