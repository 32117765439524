import { PLATFORM } from 'aurelia-pal';
import SimpleStorage from 'wootils/browser/simpleStorage';

class AppRequestsCache extends SimpleStorage {
  constructor(appLogger) {
    'inject';

    super({
      window: PLATFORM.global,
      logger: appLogger,
      initialize: false,
    });

    this._options = this._mergeOptions(this._options, {
      storage: this._getStorageConfiguration(),
      entries: Object.assign(
        {},
        this._getEntriesConfiguration(),
        {
          enabled: true,
        }
      ),
    });

    this._initialize();
  }

  save(name, reqFn) {
    let result;
    const cached = this._getEntryValue(name);
    if (cached) {
      result = Promise.resolve(cached);
    } else {
      result = this._addEntry(name, reqFn());
    }

    return result;
  }

  _getStorageConfiguration() {
    return {
      name: 'appRequestsCache',
      key: 'app-requests-cache',
    };
  }

  _getEntriesConfiguration() {
    return {};
  }
}

export { AppRequestsCache };
