class AppSession {
  isAuthenticated() {
    return true;
  }

  hasRoutePermissions() {
    return true;
  }
}

export { AppSession };
