import { dynamicOptions, inject } from 'aurelia-framework';

@dynamicOptions
@inject(Element, 'appUtils')
class ClassToggleCustomAttribute {
  constructor(element, appUtils) {
    this._element = element;
    this._appUtils = appUtils;
  }

  propertyChanged(name, newValue) {
    this._appUtils.ensureElementClass(
      this._element,
      name,
      newValue,
      !newValue
    );
  }
}

export { ClassToggleCustomAttribute };
