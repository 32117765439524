import { bindable, computedFrom } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';

class GoogleAnalyticsSnippet {
  @bindable id = '';

  idChanged(id) {
    if (id) {
      PLATFORM.global.dataLayer = PLATFORM.global.dataLayer || [];
      PLATFORM.global.gtag = function gtag(...args) {
        PLATFORM.global.dataLayer.push(...args);
      };
      PLATFORM.global.gtag('js', new Date());
      PLATFORM.global.gtag('config', this.id);
    }
  }

  @computedFrom('id')
  get scriptURL() {
    return `https://www.googletagmanager.com/gtag/js?id=${this.id}`;
  }
}

export { GoogleAnalyticsSnippet };
