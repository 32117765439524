import { PLATFORM } from 'aurelia-pal';

class WindowUtils {
  _popupDefaultOptions = {
    menubar: 0,
    width: 560,
    height: 560,
    resizable: 0,
    scrollbars: 0,
    location: 0,
    left: 0,
    top: 0,
  };

  constructor(appUtils) {
    'inject';

    this._appUtils = appUtils;
  }

  addEventListener(events, fn, ...rest) {
    const list = this._appUtils.ensureArray(events);
    list.forEach((event) => PLATFORM.global.addEventListener(...[event, fn, ...rest]));
    return {
      dispose: () => this.removeEventListener(list, fn, ...rest),
    };
  }

  removeEventListener(events, fn, ...rest) {
    const list = this._appUtils.ensureArray(events);
    list.forEach((event) => PLATFORM.global.removeEventListener(...[event, fn, ...rest]));
  }

  global(name) {
    return PLATFORM.global[name];
  }

  supportsIntl() {
    return !!this.global('Intl');
  }

  getNavigator() {
    return this.global('navigator');
  }

  openPopup(url, title, onClose = () => {}, options = {}) {
    const popupOptions = Object.assign({}, this._popupDefaultOptions, options);
    const screen = this.global('screen');
    const half = 2;
    popupOptions.left = (screen.width / half) - (popupOptions.width / half);
    popupOptions.top = (screen.height / half) - (popupOptions.height / half);
    const optionsStr = Object.keys(popupOptions)
    .map((name) => `${name}=${popupOptions[name]}`)
    .join(', ');

    const popup = PLATFORM.global.open(url, title, optionsStr);
    const closeCheck = setInterval(() => {
      try {
        if (popup === null || popup.closed) {
          clearInterval(closeCheck);
          onClose();
        }
      } catch (ignore) {
        // do nothing...
      }
    }, 1000);

    return popup;
  }

  scrollToTop() {
    PLATFORM.global.scrollTo(0, 0);
  }
}

export { WindowUtils };
