import { inject } from 'aurelia-framework';
import { boundMethod } from 'autobind-decorator';


@inject(Element, 'windowUtils')
class ClickOutsideCustomAttribute {
  constructor(element, windowUtils) {
    this._element = element;
    this._windowUtils = windowUtils;
    this._subscription = null;
  }

  attached() {
    this._subscription = this._windowUtils.addEventListener(
      'click',
      this._onClick,
      true
    );
  }

  detached() {
    this._subscription.dispose();
  }

  @boundMethod
  _onClick(event) {
    const { target } = event;
    if (this.value && this._element !== target && !this._element.contains(target)) {
      this.value();
    }
  }
}

export { ClickOutsideCustomAttribute };
