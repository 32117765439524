import { inject } from 'aurelia-framework';
import { boundMethod } from 'autobind-decorator';
import deferred from 'wootils/shared/deferred';
import { AppAPI } from './appAPI.service';

@inject(AppAPI, 'appConfiguration', 'appMessages', 'windowUtils')
class MeetupAuth {
  constructor(appAPI, appConfiguration, appMessages, windowUtils) {
    this._appAPI = appAPI;
    this._authMessageSubject = appConfiguration.get('messages.subjects.authentication');
    this._appMessages = appMessages;
    this._windowUtils = windowUtils;
    this._defer = null;
    this._unsubscribe = null;
  }

  connect() {
    let result;
    if (this._defer) {
      result = Promise.reject(new Error('There\'s another popup open'));
    } else {
      result = this._openPopup()
      .then((token) => {
        this._appAPI.setAuthorizationToken(token);
        return this._appAPI.getMyInformation();
      })
      .then((response) => response.member);
    }

    return result;
  }

  disconnect() {
    this._appAPI.setAuthorizationToken();
  }

  _openPopup() {
    this._defer = deferred();
    this._unsubscribe = this._appMessages.addListener(
      this._authMessageSubject,
      this._authMessageListener
    );
    const url = this._appAPI.endpoint('authentication');
    this._windowUtils.openPopup(url, 'Authentication', this._onPopupClose);
    return this._defer.promise;
  }

  @boundMethod
  _authMessageListener(raw, message) {
    if (this._defer) {
      if (message.status === 'success') {
        this._defer.resolve(message.token);
      } else if (message.status === 'error') {
        this._defer.reject(new Error(message.error));
      } else {
        this._defer.reject(new Error('Unknown error'));
      }

      this._unsubscribe();
      this._unsubscribe = null;
      this._defer.resolve();
      this._defer = null;
    }
  }

  @boundMethod
  _onPopupClose() {
    if (this._defer) {
      this._defer.reject(new Error('The popup was closed'));
      this._defer = null;
    }
  }
}

export { MeetupAuth };
