import { inject } from 'aurelia-framework';
import * as constants from '../../constants';

@inject(
  'appConfiguration',
  'appRouting',
  'appRoutingStep',
  constants.NAVIGATION
)
class AppContainer {
  constructor(
    appConfiguration,
    appRouting,
    appRoutingStep,
    NAVIGATION
  ) {
    this.gaSettings = appConfiguration.get('features.googleAnalytics');
    this._appHTMLTitle = appConfiguration.get('appHTMLTitle');
    this._appRouting = appRouting;
    this._appRoutingStep = appRoutingStep;
    this._NAVIGATION = NAVIGATION;
  }

  configureRouter(config) {
    // eslint-disable-next-line no-param-reassign
    config.title = this._appHTMLTitle;
    // eslint-disable-next-line no-param-reassign
    config.options.pushState = true;
    config.addPipelineStep('authorize', this._appRoutingStep);
    config.map(this._appRouting.extractRoutes(this._NAVIGATION));
    config.fallbackRoute('home');
  }
}

export { AppContainer };
