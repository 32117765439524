class AppRouting {
  constructor(appSession) {
    'inject';

    this._appSession = appSession;
    this._routesDefaultSettings = {
      requiresAnonymous: false,
      requiresAuthorization: true,
      requiresPermissions: [],
    };
  }

  validate(
    name = '',
    url = '',
    routeSettings = {},
    authenticationRoute = 'login',
    fallbackRoute = 'home'
  ) {
    const result = {
      allowed: false,
      denied: true,
      redirectTo: '',
      query: {},
    };

    const newSettings = this._normalizeRouteSettings(routeSettings);
    const isAuthenticated = this._appSession.isAuthenticated();
    if (newSettings.requiresAuthorization && !isAuthenticated) {
      result.redirectTo = authenticationRoute;
      if (name !== authenticationRoute && url.match(/^\/\w/)) {
        result.query = {
          redirect: encodeURIComponent(url),
        };
      }
    } else if (
      (newSettings.requiresAnonymous && isAuthenticated) ||
      (
        newSettings.requiresPermissions &&
        !this._appSession.hasRoutePermissions(newSettings.requiresPermissions)
      )
    ) {
      result.redirectTo = fallbackRoute;
    } else {
      result.allowed = true;
    }

    result.denied = !result.allowed;
    return result;
  }

  canAccess(routeSettings = {}) {
    return this.validate(routeSettings).allowed;
  }

  extractRoutes(definition) {
    return Object.keys(definition)
    .map((key) => this._getRoutesFromList(definition[key]))
    .reduce((currentList, list) => [...currentList, ...list], []);
  }

  _normalizeRouteSettings(settings) {
    const result = Object.assign({}, settings);
    Object.keys(this._routesDefaultSettings).forEach((settingName) => {
      if (typeof result[settingName] === 'undefined') {
        result[settingName] = this._routesDefaultSettings[settingName];
      }
    });

    if (result.requiresAnonymous) {
      result.requiresAuthorization = false;
      result.requiresPermissions = [];
    } else if (result.requiresAuthorization) {
      result.requiresAnonymous = false;
    }

    return result;
  }

  _getRoutesFromList(list) {
    const result = [];
    list.forEach((route) => {
      if (route.moduleId) {
        const newRoute = Object.assign({}, route);
        delete newRoute.items;
        result.push(newRoute);
      }
      if (route.items && route.items.length) {
        result.push(...this._getRoutesFromList(route.items));
      }
    });

    return result;
  }
}

export { AppRouting };
