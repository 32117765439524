import { inject } from 'aurelia-framework';
import { errorsFormatter, MeetupAuth, Slack } from '../../services';

@inject('appConfiguration', MeetupAuth, 'router', Slack)
class SlackContainer {
  member = null;
  loading = false;
  email = '';
  error = '';
  success = false;

  constructor(appConfiguration, meetupAuth, router, slack) {
    this._meetupLink = appConfiguration.get('social.meetup');
    this._meetupAuth = meetupAuth;
    this._router = router;
    this._slack = slack;
  }

  activate() {
    this._reset();
  }

  cancel() {
    this._router.navigateToRoute('home');
  }

  connect() {
    this._reset();
    this.loading = true;
    this._meetupAuth.connect()
    .then((member) => {
      this.member = member;
      this.email = member.email;
    })
    .catch((error) => {
      this.error = errorsFormatter.parse(error).message.replace(
        /\{\{MEETUP_LINK\}\}/,
        this._meetupLink
      );
    })
    .then(() => {
      this.loading = false;
    });
  }

  disconnect() {
    this._reset();
    this._meetupAuth.disconnect();
  }

  send(email) {
    this.loading = true;
    this._slack.invite(email)
    .then(() => {
      this.success = true;
    })
    .catch((error) => {
      this.error = errorsFormatter.parse(error).message;
    })
    .then(() => {
      this.loading = false;
    });
  }

  _reset() {
    this.member = null;
    this.email = '';
    this.error = '';
  }
}

export { SlackContainer };
