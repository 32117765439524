import autobind from 'autobind-decorator';

class MessagesChannel {
  constructor(windowUtils, prefix = 'app') {
    this._prefix = prefix;
    this._windowUtils = windowUtils;
    this._subjectRegex = /^(\w+)\:/i;
    this._listeners = {};
    this._unsubscribe = null;
  }

  setPrefix(prefix) {
    this._prefix = prefix;
    return this;
  }

  addListener(subject, fn) {
    if (!this._listeners[subject]) {
      this._listeners[subject] = [];
    }

    this._listeners[subject].push(fn);
    this.start();
    return () => this.removeListener(subject, fn);
  }

  removeListener(subject, fn) {
    const index = this._listeners[subject].indexOf(fn);
    if (index > -1) {
      this._listeners[subject].splice(index, 1);
    }
    this._stop();
  }

  start() {
    if (!this._unsubscribe) {
      this._unsubscribe = this._windowUtils.addEventListener(
        'message',
        this._listener
      );
    }
  }

  stop() {
    if (this._unsubscribe) {
      this._unsubscribe.dispose();
      this._unsubscribe = null;
    }
  }

  _stop() {
    if (this._unsubscribe) {
      const atLeastOne = Object.keys(this._listener)
      .some((subject) => this._listener[subject].length > 0);
      if (!atLeastOne) {
        this.stop();
      }
    }
  }

  @autobind
  _listener(event) {
    if (
      event.data &&
      typeof event.data === 'string' &&
      event.data.startsWith(this._prefix)
    ) {
      const message = event.data.substr(this._prefix.length);
      if (this._subjectRegex.test(message)) {
        const [, subject] = this._subjectRegex.exec(message);
        if (this._hasListenersForSubject(subject)) {
          const body = message.substr(`${subject}:`.length).trim();
          let jsonBody = {};
          if (body.startsWith('{') && body.endsWith('}')) {
            try {
              jsonBody = JSON.parse(body);
            } catch (ignore) {
              // do nothing...
            }
          }

          this._notifyListeners(subject, body, jsonBody);
        }
      }
    }
  }

  _hasListenersForSubject(subject) {
    const listeners = this._listeners[subject];
    return listeners && Array.isArray(listeners) && listeners.length > 0;
  }

  _notifyListeners(subject, body, jsonBody) {
    const listeners = this._listeners[subject];
    if (listeners) {
      listeners.forEach((listener) => {
        listener(body, jsonBody);
      });
    }
  }
}

export { MessagesChannel };
