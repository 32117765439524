import { inject } from 'aurelia-framework';
import { AppAPI } from './appAPI.service';

@inject(AppAPI)
class Slack {
  constructor(appAPI) {
    this._appAPI = appAPI;
  }

  invite(email) {
    return this._appAPI.inviteUserToSlack(email);
  }
}

export { Slack };
