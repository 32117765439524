import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import * as constants from './constants';
import * as services from './services';
import * as steps from './steps';

const normalizeClassName = (name) => name.replace(/^([A-Z]+)/, (ignore, match) => {
  let result;
  if (match.length > 1) {
    const limit = match.length - 1;
    const start = match.substr(0, limit).toLowerCase();
    const end = match.substr(limit);
    result = `${start}${end}`;
  } else {
    result = match.toLowerCase();
  }

  return result;
});


export const configure = (frameworkConfig, options = {}) => {
  const { container } = frameworkConfig;

  // Library overrides
  let overrides;
  if (options.overrides) {
    overrides = typeof options.overrides === 'function' ?
      options.overrides(frameworkConfig, options) :
      options.overrides;
  } else {
    overrides = {};
  }

  // Framework services
  container.registerAlias(EventAggregator, 'eventAggregator');
  container.registerAlias(HttpClient, 'httpClient');
  container.registerAlias(Router, 'router');

  // Constants
  Object.keys(constants).forEach((constantName) => {
    const constantValue = overrides.constants && overrides.constants[constantName] ?
      overrides.constants[constantName] :
      constants[constantName];
    container.registerInstance(constantName, constantValue);
  });

  // Services
  Object.keys(services).forEach((serviceClassName) => {
    const serviceName = normalizeClassName(serviceClassName);
    const serviceValue = overrides.services && overrides.services[serviceName] ?
      overrides.services[serviceName] :
      services[serviceClassName];
    container.registerSingleton(serviceName, serviceValue);
  });

  // Steps
  Object.keys(steps).forEach((stepClassName) => {
    const stepName = normalizeClassName(stepClassName);
    const stepValue = overrides.steps && overrides.steps[stepName] ?
      overrides.steps[stepName] :
      steps[stepClassName];
    container.registerInstance(stepName, stepValue);
  });

  // Load the configuration service
  const appConfiguration = container.get('appConfiguration');

  // Add the project configuration
  appConfiguration.setConfig(options.configuration || {});

  // Error handling
  // - Publisher
  container.get('appErrorPublisher').start();

  // Custom attributes and value converters
  frameworkConfig.globalResources(
    PLATFORM.moduleName('./customAttributes/classToggle.customAttribute'),
    PLATFORM.moduleName('./customAttributes/realHeight.customAttribute'),
    PLATFORM.moduleName('./customAttributes/clickOutside.customAttribute'),
    PLATFORM.moduleName('./customAttributes/dynamicClass.customAttribute'),
    PLATFORM.moduleName('./customAttributes/windowsSelector.customAttribute'),
    PLATFORM.moduleName('./valueConverters/limitTo.valueConverter'),
    PLATFORM.moduleName('./valueConverters/listFilter.valueConverter'),
    PLATFORM.moduleName('./valueConverters/transform.valueConverter')
  );
};
