import { PLATFORM } from 'aurelia-pal';

export const NAVIGATION = {
  unmapped: [
    {
      name: 'home',
      route: ['', 'home'],
      moduleId: PLATFORM.moduleName('containers/home/home.container'),
    },
    {
      name: 'slack',
      route: ['slack'],
      moduleId: PLATFORM.moduleName('containers/slack/slack.container'),
      title: 'Unite nuestro Slack',
      settings: {
        requiresAuthorization: false,
      },
    },
  ],
};
