import { AppLocalStore } from './appLocalStore.service';

class AppSessionStore extends AppLocalStore {
  _getStorageConfiguration() {
    return {
      name: 'appSessionStore',
      key: 'app-session-store',
      typePriority: [
        'session',
        'temp',
        'local',
      ],
    };
  }
}

export { AppSessionStore };
