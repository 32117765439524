import { inject } from 'aurelia-framework';

@inject('appUtils', Element, 'windowUtils')
class WindowsSelectorCustomAttribute {
  constructor(appUtils, element, windowUtils) {
    this._appUtils = appUtils;
    this._element = element;
    this._isWindow = windowUtils.getNavigator().userAgent.match(/\(Windows\s/);
    this._cssClass = 'windows-os';
  }

  attached() {
    this._ensureClass(true, false);
  }

  detached() {
    this._ensureClass(false, true);
  }

  _ensureClass(addIfNoPresent, removeIfPresent) {
    if (this._isWindow) {
      this._appUtils.ensureElementClass(
        this._element,
        this._cssClass,
        addIfNoPresent,
        removeIfPresent
      );
    }
  }
}

export { WindowsSelectorCustomAttribute };
