/* eslint-disable no-process-env */
import { PLATFORM } from 'aurelia-pal';

const appContainer = PLATFORM.moduleName('containers/app/app.container');

export const configure = (aurelia) => {
  aurelia.use
  .plugin(PLATFORM.moduleName('lib/haut'), {
    configuration: process.env.CONFIG,
  })
  .standardConfiguration();

  return aurelia.start().then(() => aurelia.setRoot(appContainer));
};
