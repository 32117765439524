/**
 * A value converter that can be used to slice strings and lists.
 */
class LimitToValueConverter {
  /**
   * This is the method called by the framework when the limit is applied. It just calls `.slice`
   * on the recevied target.
   * @param {Array|String}   target         The target to slice.
   * @param {Number}         limit          The length the target should have.
   * @param {?Number}        [begin=null]   If defined, the slice will begin on the given index.
   * @return {Array|String}
   */
  toView(target, limit, begin = null) {
    return target.slice(begin, limit === null ? 0 : (begin + limit));
  }
}

export { LimitToValueConverter };
