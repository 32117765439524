class HomeContainer {
  constructor(appConfiguration) {
    'inject';

    const social = appConfiguration.get('social');
    this.meetupLink = social.meetup;
    this.discordLink = social.discord;
  }

  _unnecesaryMethodBecauseMyBabelPluginIsOutdated() { }
}

export { HomeContainer };
