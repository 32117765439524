import { bindable, noView } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';

@noView()
class ScriptInjector {
  @bindable src = '';
  @bindable async = false;

  tag = null;

  attached() {
    if (this.src) {
      this.tag = PLATFORM.global.document.createElement('script');
      if (this.async) {
        this.tag.async = true;
      }

      this.tag.setAttribute('src', this.src);
      PLATFORM.global.document.body.appendChild(this.tag);
    }
  }

  detached() {
    if (this.tag) {
      this.tag.remove();
      this.tag = null;
    }
  }
}

export { ScriptInjector };
