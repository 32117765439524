/**
 * A value converter that can be used to transform an object into _"something else"_. For example,
 * if you have to apply multiple filters and _maps_ to a list, instead of adding the `listFilter`
 * and the `listMap`, use this converter and make the _"tansformation"_ on the view controller.
 */
class TransformValueConverter {
  /**
   * This is the method called by the framework when the converter is applied. It just invokes
   * the function with the target object.
   * @param {*}        target The object to transform.
   * @param {Function} fn     The function that will be used to transform the object.
   * @return {*}
   */
  toView(target, fn) {
    return fn(target);
  }
}

export { TransformValueConverter };
