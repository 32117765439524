import Parserror from 'parserror';

const errorsFormatter = Parserror
.new()
.addCase({
  name: 'authCancelation',
  condition: /^the popup was closed$/i,
  message: 'La ventana de autenticación se cerró. Hace click en "conectar" para abrirla de nuevo',
})
.addCase({
  name: 'authTwice',
  condition: /^there's another popup open$/i,
  message: 'La ventana de autenticación ya se encuentra abierta',
})
.addCase({
  name: 'unknownError',
  condition: /^unknown error$/i,
  message: 'Ocurrió un error desconocido, por favor intenta de nuevo',
})
.addCase({
  name: 'invalidEmailAddress',
  condition: /invalid email address/i,
  message: 'Por favor ingresa una dirección de email válida',
})
.addCase({
  name: 'invalidToken',
  condition: /unauthorized/i,
  message: 'Ocurrió un error con la autenticación. Por favor intenta conectar tu cuenta nuevamente',
})
.addCase({
  name: 'invalidMember',
  condition: /^invalid member$/i,
  message: (
    'Parece que todavía no eres parte de nuestro grupo. Puedes hacer ' +
      '<a href="{{MEETUP_LINK}}" title="Unirte a BeerJSCba" target="blank" ' +
      'rel="noreferrer">click aquí para unirte</a>'
  ),
});

export { errorsFormatter };
