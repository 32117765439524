import { boundMethod } from 'autobind-decorator';
import { inject } from 'aurelia-framework';
import { debounce } from 'throttle-debounce';

@inject(Element, 'windowUtils')
class RealHeightCustomAttribute {
  constructor(element, windowUtils) {
    this._element = element;
    this._windowUtils = windowUtils;
    this._subscription = null;
    this._defaultHeight = 100;
    this._requiredHeight = this._defaultHeight;
    this._lastHeightRequired = null;
    this._lastWindowHeight = null;
    this._isJSDOM = this._windowUtils.getNavigator().userAgent.match(/ jsdom\//);

    const updateDebounceTime = 400;
    this._updateHeightListener = debounce(
      updateDebounceTime,
      this._updateHeight
    );
  }

  valueChanged(height) {
    let use = this._defaultHeight;
    if (height) {
      const asNumber = Number(height);
      if (!Number.isNaN(asNumber)) {
        use = asNumber;
      }
    }

    this._requiredHeight = use;
    this._updateHeight();
  }

  attached() {
    this._subscription = this._windowUtils.addEventListener(
      'resize',
      this._updateHeightListener
    );

    this._updateHeight();
  }

  detached() {
    this._subscription.dispose();
  }

  @boundMethod
  _updateHeight() {
    if (!this._isJSDOM) {
      let windowHeightChanged = false;
      const windowHeight = this._windowUtils.global('innerHeight');
      if (!this._lastWindowHeight || this._lastWindowHeight !== windowHeight) {
        windowHeightChanged = true;
        this._lastWindowHeight = windowHeight;
      }

      let requiredHeightChanged = false;
      if (
        !this._lastHeightRequired ||
        this._lastHeightRequired !== this._requiredHeight
      ) {
        requiredHeightChanged = true;
        this._lastHeightRequired = this._requiredHeight;
      }

      if (windowHeightChanged || requiredHeightChanged) {
        const all = 100;
        const newHeight = (windowHeight * this._requiredHeight) / all;
        this._element.style.height = `${newHeight}px`;
      }
    }
  }
}

export { RealHeightCustomAttribute };
